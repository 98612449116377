import React from "react"
import { Profile } from "src/components"
import Layout from "src/layout"

const ProfilePage: React.FC = () => {
  return (
    <Layout>
      <Profile />
    </Layout>
  )
}

export default ProfilePage
